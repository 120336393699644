export const DRUG_NAME_LABEL = 'DRUG NAME'
export const DRUG_NAME_PLACEHOLDER = 'What drug are you looking for?'
export const ZIP_CODE_LABEL = 'ZIP CODE'
export const BRAND_INDICATOR = 'TYPE'
export const GENERIC_LABEL = "GENERIC"
export const BRAND_LABEL = "BRAND"
export const ZIP_CODE_PLACEHOLDER = 'Where are you?'

export const cardholderID = '5522'
export const groupID = '5522'
export const rxBIN = '015558'
export const rxPCN = 'HT'
export const textGroupID = '5522'
export const defaultGroupId = groupID
